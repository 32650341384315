import React from 'react';
// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
// import { SidebarData } from './Sidebardata';
import './Navbar.css';
// import { IconContext } from 'react-icons';


function NavbarTop() {

    
  return (
    <>
        {/* <IconContext.Provider value={{ color:'grey' }}> */}
       

        <nav className="navbar-top">

                    <ul className="navbar-top-container">
                        
                       <li className=""> <Link to='/about-aura' > About </Link> </li>
                       <li className=""> <Link to='/experiences' > Aura Muse </Link> </li>
                       
                       <li className=""> <Link to='/himalayan-voyages' > Himalayan Voyages </Link> </li>
                       <li className=""> <Link to='/abled-traveller' > Abled Traveller </Link> </li>
                        {/* <li className=""> <Link to='/chat' > Chat </Link> </li> */}

                        <li className=""> <Link to='/be-responsible' > beResponsible </Link> </li>
                        <li className=""> <Link to='/enquire' > Enquire </Link> </li>
                        

                    </ul>
                    
        </nav>
    

       
        {/* </IconContext.Provider> */}
    </>
      
    
  )
}

export default NavbarTop;
