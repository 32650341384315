import React from 'react'
import './Styles.css';
import ImgLogoAbled from './Images/ImgLogoAbled.svg';
import CardAbled1 from './Images/CardAbled1.png';
import CardAbled2 from './Images/CardAbled2.png';
import CardAbled3 from './Images/CardAbled3.png';
import CardAbled4 from './Images/CardAbled4.png';
import CardAbled5 from './Images/CardAbled5.png';
import CardAbled6 from './Images/CardAbled6.png';
import CardAbled7 from './Images/CardAbled7.png';
import CardAbled8 from './Images/CardAbled8.png';



function AbledPg() {
  return (

    <>

    <div className='BgAbledPg'>
        <div className="HeadAbledPg"> Being Abled is a rare ability to dare </div>
        <div className="SubHeadAbledPg"> Abled Traveller's Program </div>
    </div>

    <div className='SecAbledIntro'> 
        <div className="TextDiv">
        <p className='TextAbledBanner'>
           We are delighted to announce our vision, deep investment of effort and focus towards enabling the abled travelers move around the Indian Region. With sincere words we promise our travelers best of the experiences and best of comfort through the Abled Travelers Program. Keeping in mind all the necessities and expectations, we are here to deliver with our existing know-how and the Aura family team. Based on our facilities, industry experience, we will take this to a whole new reality.  
        </p>
        <p className='LinkAbledBanner'> 
            <div> <a href="http://www.abledtraveller.com"> AbledTraveller.com </a> </div> 
        </p>
        </div>

        <img className='ImgLogoAbled' src={ImgLogoAbled} />
    
    </div>


    <div className='AllCardsAbled'> 

<div className='CardAbled '> 
      <p className='CardHead'> Customised Choice of Programs for the Abled traveller </p>
      <div className='CardAbledImgDiv'> <img className='CardAbledImg' src={CardAbled1} />  </div>
</div>

<div className='CardAbled '> 
      <p className='CardHead'> Abled Location Options Only (Hotels, Restaurants, Monuments) </p>
      <img className='CardAbledImg' src={CardAbled2} />
</div>

<div className='CardAbled '> 
      <p className='CardHead'> Abled Transport Systems Only </p>
      <img className='CardAbledImg' src={CardAbled3} />
</div>

<div className='CardAbled '> 
      <p className='CardHead'> Softly Trained Guides & Drivers Only </p>
      <img className='CardAbledImg' src={CardAbled4} />
</div>

<div className='CardAbled '> 
      <p className='CardHead'>Managing logistics for difficult locations and on-demand </p>
      <img className='CardAbledImg' src={CardAbled5} />
</div>

<div className='CardAbled '> 
      <p className='CardHead' > Abled-only Preferred Temples with our Logistics </p>
      <img className='CardAbledImg' src={CardAbled6} />
</div>

<div className='CardAbled '> 
      <p className='CardHead'> Experiences with friendly animals, like Camels, Horses and Elephants </p>
      <img className='CardAbledImg' src={CardAbled7} />
</div>

<div className='CardAbled '> 
      <p className='CardHead' > Medical Attendant Facility for the Abled Travelers </p>
      <img className='CardAbledImg' src={CardAbled8} />
</div>


</div>

    

    </>
    
  )
}

export default AbledPg