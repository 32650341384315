import React from 'react'
import './Styles.css';

function Contact() {
  return (
    
    <>

    <div className='ContactBg'> 

        <div className='ContactPgCont'>

          <div className="HeadContactPg "> 
            
            <p> Contact Us  </p> <br />
            </div>
            
          
          <div className='CardDisp'>

            <p className="AddressCard"> Registered Address <br /> <br /> Aura Voyages Private Limited <br /> Block C-131, 7th floor, National Apartment, Dwarka Sector 3, New Delhi - 110059, India
            <br /> e: info@auravoyages.com <br /> <br /> CIN: U63000DL2014PTC266207 <br />  </p>

            <p className="AddressCard"> Office Location <br /> <br /> Aura Voyages Private Limited <br /> Sunview Apartment, Flat 384, Sector 11, Pocket 4, Dwarka, New Delhi – 110078, India </p>

            <p className="AddressCard">  China Office <br /> <br /> Ms Heidi <br /> 
            <br /> T: 0086-13910387047 <br />  heidi@beocean.cn | heidi@easychinatours.com <br />
            Office Address: No.029 First floor, Building C, No. 65 Nan Sanhuan Xilu, Fengtai District, Beijing, China </p>

            <p className="AddressCard"> France Office <br /> <br /> Représentant en France - Aura voyages <br /> Mr Philippe Ugnon-Fleury <br /> 
            <br /> T: +33.6.15.39.31.88 <br /> philippe@auravoyages.com <br />
                Office Address: Région de Lyon </p>
            
            </div>


            
            
                          <div className='CardDisp'> 

                               <p className='ContactCard'> Aura Voyages + Abled Traveller <br /> <br /> Ravinder Singh ATRI <br />  +91 98107 20202 (m/whatsapp) <br /> e: info@auravoyages.com <br /> 
                               <a href='https://www.linkedin.com/in/ravinder-singh-atri-teacher-of-yoga-accessible-travel-designer-3a133424/'> 
                               linkedin - Ravinder Atri</a> </p>



                              <p className='ContactCard'> Aura Voyages + Abled Traveller <br /> <br /> Arun Sharma <br /> +91 98106 81018 (m/whatsapp)  <br /> e: info@abledtraveller.com <br />
                              <a href='https://www.linkedin.com/in/arun-sharma-172154ba/'> 
                               linkedin - Arun Sharma</a> </p>



                              <p className='ContactCard'> Himalayan Voyages <br /> <br /> Virender Singh ATRI <br /> +91 95993 34588 (m/whatsapp) <br /> e: info@himalayanvoyages.com <br />
                              <a href='https://www.linkedin.com/in/virender-singh-99715359/'> 
                               linkedin - Virender Atri</a>  </p>

                            </div>
                           
                
            
          


        </div>

    </div>


    </>


  )
}



export default Contact
